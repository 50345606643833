* {
    box-sizing: border-box;
}

html, body, #root {
    height: 100%;
}


body {
    margin: 0;
    padding: 0;
    font-family: 'EB Garamond', serif;
    font-size: 20px;
}

p,
h1, h2, h3, h4, h5 {
    margin: 0;
}
